import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import searchStyles from "./searchStyles";

function Search({classes}) {
  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton}>
        <MenuIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Search..."
      />
      <IconButton className={classes.iconButton}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default withStyles(searchStyles)(Search);
