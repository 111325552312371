import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import signInStyles from "./signInStyles";
import {withStyles} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import Link from "@material-ui/core/Link";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import qs from "query-string";
import SmallBlockLayout from '../SmallBlockLayout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import GoogleSignIn from "../GoogleSignIn";
import afterAuthorizeRedirect from '../afterAuthorizeRedirect/afterAuthorizeRedirect';
import {connectService} from "global-apps-common";
import AuthContext from "../../modules/auth/AuthContext";

function SignInScreenView({classes, redirectURI, onSubmit, accountData, onChange}) {
  return (
    <SmallBlockLayout title="Global App Store">
      <Typography variant="h5">
        Already have an account? Sign in
      </Typography>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          name="username"
          label="Username"
          fullWidth
          value={accountData.username}
          onChange={event => onChange(event, 'username')}
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          value={accountData.password}
          onChange={event => onChange(event, 'password')}
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign In
        </Button>
        <GoogleSignIn/>
      </form>
      <Link
        className={classes.signLink}
        href={'/signUp' + (redirectURI ? "?redirectURI=" + redirectURI : '')}
        variant="body2"
      >
        Don't have an account? Sign Up
      </Link>
    </SmallBlockLayout>
  );
}

function SignInScreen({location, history, classes, onSubmit, preregisterData, authorized}) {
  const {redirectURI} = qs.parse(location.search);
  const [accountData, setAccountData] = useState({
    username: null,
    password: null
  });

  useEffect(() => {
    if (preregisterData && !authorized) {
      if (redirectURI) {
        history.push('/signUp?redirectURI=' + redirectURI)
      } else {
        history.push('/signUp')
      }
    }
  }, [preregisterData, authorized]);

  const props = {
    classes,
    accountData,
    redirectURI,

    onSubmit(event) {
      event.preventDefault();
      onSubmit(accountData.username, accountData.password);
    },

    onChange(event, field) {
      setAccountData({...accountData, [field]: event.target.value});
    }
  };

  return <SignInScreenView{...props}/>
}

export default withWidth()(
  withRouter(
    withSnackbar(
      afterAuthorizeRedirect(
        connectService(AuthContext, ({preregisterData, authorized}, authService, {enqueueSnackbar}) => ({
          preregisterData,
          authorized,
          onSubmit(username, password) {
            authService.login(username, password)
              .catch(error => enqueueSnackbar(error.message, {
                variant: 'error'
              }));
          }
        }))(
          withStyles(signInStyles)(SignInScreen)
        )
      )
    )
  )
);
