const myApplicationBlockStyles = theme => ({
  card: {
    margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    position: 'relative',
    overflow: 'hidden',
    width: theme.spacing(20),
    height: '100%'
  },
  cardImage: {
    objectFit: 'contain'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  appName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: `''`,
      position: 'absolute',
      right: 0,
      top: 0,
      width: theme.spacing(5),
      height: '100%',
      background: 'linear-gradient(to right, transparent 0px, white)'
    }
  },
  appLink: {
    textDecoration: 'none',
    display: 'block'
  },
  cardActions: {
    paddingTop: 0
  },
  cardContentRoot: {
    padding: `${theme.spacing(1)}px !important`,
    textAlign: 'center'
  },
  cardActionImage: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  }
});

export default myApplicationBlockStyles;
