import React, {useEffect} from 'react';
import qs from "query-string";
import {connectService} from "global-apps-common";
import AuthContext from "../../modules/auth/AuthContext";
import {withSnackbar} from "notistack";
import {useHistory, useLocation} from "react-router-dom";

function afterAuthorizeRedirect(Component) {
  function AfterAuthorizeRedirectHOC({authorized, token, onOAuthRedirect, ...otherProps}) {
    const location = useLocation();
    const history = useHistory();
    const {redirectURI} = qs.parse(location.search);

    useEffect(() => {
      if (authorized) {
        if (redirectURI) {
          onOAuthRedirect();
        } else {
          history.push('/');
        }
      }
    }, [authorized]);

    useEffect(() => {
      if (token) {
        const {url, query} = qs.parseUrl(redirectURI);
        query.token = token;
        window.location.href = `${url}?${qs.stringify(query)}`;
      }
    }, [token]);

    return <Component {...otherProps}/>;
  }

  return withSnackbar(
    connectService(AuthContext, ({authorized, token}, authService, {enqueueSnackbar}) => ({
      token,
      onOAuthRedirect() {
        return authService.generateAuthToken()
          .catch(error => enqueueSnackbar(error.message, {
            variant: 'error'
          }));
      },
      authorized
    }))(AfterAuthorizeRedirectHOC)
  );
}

export default afterAuthorizeRedirect;
