import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {withStyles} from "@material-ui/core";
import screenshotsStyles from "./screenshotsStyles";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30
  }
};

function Screenshots({classes}) {
  return (
    <Carousel responsive={responsive} className={classes.slider}>
      <img src="https://lh3.googleusercontent.com/SyWoyThTsSv8w2Q7Vc5jjF2nMzyFwIeKoc42wxTS0HpHvsPpdoKCgQEijBj_xMLB2zA=w720-h310-rw" alt=""/>
      <img src="https://lh3.googleusercontent.com/9laYd7oslX_Z2yn9bwD3i9lOr_RdjSc_ZJbnLE3c0lgMWjo5LTplgbsKxWQVKhR5rMM=w720-h310-rw" alt=""/>
      <img src="https://lh3.googleusercontent.com/S2UeQhvF7w4c0_MsdLffAeEUANFPQT3jj6bgERI6UnEA47sOK-idb7LZhhlmSFtyImQ=w720-h310-rw" alt=""/>
      <img src="https://lh3.googleusercontent.com/VkcUY40lgCVeqpGlc5qY0aJOmm0yH1yG1VOVTtUyY2E8WTguin5Am8Y9HVm_BBAV2FH6=w720-h310-rw" alt=""/>
      <img src="https://lh3.googleusercontent.com/FDhEKa9KVWG5jfO4J5qfdiCBBQMkqnQvnVBtpwI2CyUuSJRkTZo3mW_0_oJPhwKbj_Td=w720-h310-rw" alt=""/>
    </Carousel>
  );
}

export default withStyles(screenshotsStyles)(Screenshots);
