const googleSignInStyles = () => {
  return ({
    googleButton: {
      '& > div > div > div': {
        position: 'absolute'
      }
    }
  });
};

export default googleSignInStyles;
