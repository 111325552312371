const mainLayoutStyles = () => ({
  root: {
    minHeight: '100vh',
    flexDirection: 'column'
  },
  rootGrid: {
    display: 'flex',
    flexGrow: 1
  }
});

export default mainLayoutStyles;
