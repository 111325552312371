import React from 'react';
import {BrowserRouter as ReactRouter, Route, Switch} from 'react-router-dom';
import cookies from 'js-cookie';
import {MuiThemeProvider} from '@material-ui/core/styles';
import AuthContext from '../modules/auth/AuthContext';
import AuthService from '../modules/auth/AuthService';
import SignInScreen from './SignInScreen';
import SignUpScreen from './SignUpScreen';
import OAuthScreen from "./OAuthScreen";
import theme from '../components/theme/themeConfig';
import {SnackbarProvider} from "notistack";
import CssBaseline from '@material-ui/core/CssBaseline';
import ProfileScreen from "./ProfileScreen";
import ApplicationsScreen from "./ApplicationsScreen";
import InstallAppScreen from './InstallAppScreen';
import SubscriptionsScreen from "./SubscriptionsScreen";
import PaymentsScreen from "./PaymentsScreen";
import ApplicationInfoScreen from './ApplicationInfoScreen'

const authService = AuthService.createFrom(cookies);
authService.init();

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline/>
      <AuthContext.Provider value={authService}>
        <SnackbarProvider maxSnack={1}>
          <ReactRouter>
            <Switch>
              <Route exact path="/" component={ApplicationsScreen}/>
              <Route exact path="/install/:appId/:hostingId" component={InstallAppScreen}/>
              <Route exact path="/signIn" component={SignInScreen}/>
              <Route exact path="/oauth" component={OAuthScreen}/>
              <Route exact path="/signUp" component={SignUpScreen}/>
              <Route exact path="/apps" component={ApplicationsScreen}/>
              <Route exact path="/apps/:category" component={ApplicationsScreen}/>
              <Route exact path="/profile" component={ProfileScreen}/>
              <Route exact path="/payments" component={PaymentsScreen}/>
              <Route exact path="/subscriptions" component={SubscriptionsScreen}/>
              <Route exact path="/:appName" component={ApplicationInfoScreen}/>
            </Switch>
          </ReactRouter>
        </SnackbarProvider>
      </AuthContext.Provider>
    </MuiThemeProvider>
  );
}

export default App;
