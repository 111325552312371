import {Service, GlobalAppStoreAPI} from 'global-apps-common';

const cookieKey = 'SESSION_ID';

class AuthService extends Service {
  constructor(cookies, globalAppStoreAPI) {
    super({
      loginError: null,
      authorized: false,
      hosting: null,
      app: null,
      preregisterData: null,
      token: null
    });
    this._cookies = cookies;
    this._globalAppStoreAPI = globalAppStoreAPI;
  }

  static createFrom(cookies) {
    return new AuthService(cookies, GlobalAppStoreAPI.create());
  }

  init() {
    const sessionId = this._cookies.get(cookieKey);

    if (sessionId) {
      this.setState({
        authorized: true
      })
    }
  }

  async login(username, password) {
    try {
      await this._globalAppStoreAPI.login(username, password);
      this.setState({
        authorized: true
      });
    } catch (e) {
      this.setState({
        loginError: e.message
      });
      throw e;
    }
  }

  async signup(newUser) {
    await this._globalAppStoreAPI.signup(newUser);
    await this.login(newUser.username, newUser.password);
  }

  async signupByGoogle(newUser) {
    await this._globalAppStoreAPI.signupByGoogle(newUser);
    this.setState({
      authorized: true
    });
  }

  async logout() {
    await this._globalAppStoreAPI.logout();
    this._cookies.remove(cookieKey);

    this.setState({
      authorized: false
    });
  }

  async generateAuthToken() {
    const token = await this._globalAppStoreAPI.generateAuthToken();
    this.setState({token});
    return token;
  };

  async fetchRedirectURL(redirectURI) {
    if (!redirectURI) {
      return new TypeError('Incorrect redirect URL');
    }

    const {hosting, app} = await this._globalAppStoreAPI.getHostingInfo(redirectURI);
    return this.setState({hosting, app});
  }

  async authWithGoogle(tokenCode) {
    try {
      const preregisterData = await this._globalAppStoreAPI.sendGoogleProfileCode(tokenCode);

      this.setState({
        authorized: preregisterData.authorized,
        preregisterData: preregisterData || null
      });
    } catch (e) {
      this.setState({
        loginError: e.message
      });
      throw e;
    }
  }
}

export default AuthService;
