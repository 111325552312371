const searchStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(50),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: theme.spacing(1),
  }
});

export default searchStyles;
