import React, {useEffect, useMemo} from 'react';
import Grid from '@material-ui/core/Grid';
import {connectService} from 'global-apps-common';
import AppsService from '../../modules/apps/AppsService';
import ApplicationBlock from '../ApplicationBlock';
import MainLayout from '../MainLayout';
import {withSnackbar} from "notistack";
import checkAuth from '../../common/checkAuth';
import CircularProgress from '../CircularProgress';
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import applicationsScreenStyles from './applicationsScreenStyles'
import {withRouter} from "react-router-dom";
import MyApplicationBlock from '../MyApplicationBlock'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function ApplicationsScreen({classes, ready, apps, match, onStart, onEnd, enqueueSnackbar}) {
  useEffect(() => {
    onStart()
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      });
    return onEnd;
  }, []);

  const installedApps = useMemo(() => {
    const installedApps = {};

    for (const app of apps.values()) {
      const readyHosting = [...app.registry.values()].find(({status}) => status === 'OK');
      const pendingHosting = !readyHosting && [...app.registry.values()].find(({status}) => status === 'PENDING');
      if (pendingHosting || readyHosting) {
        installedApps[app.id] = pendingHosting || readyHosting;
      }
    }

    return installedApps;
  }, [apps]);

  return (
    <MainLayout>
      <Paper className={classes.tabsPaper}>
        <Tabs
          value={1}
          onChange={() => {
          }}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="Categories"
            classes={{wrapper: classes.tabWrapper, root: classes.tabRoot}}
            icon={<ExpandMoreIcon className={classes.expandMoreIcon}/>}
          />
          <Tab label="Home"/>
          <Tab label="Top Charts"/>
          <Tab label="Favourites"/>
          <Tab label="What's New"/>
        </Tabs>
      </Paper>
      <Grid className={classes.appsContainer}>
        <Grid container justify="center">
          <Typography variant="h5" className={classes.basicTitle}>
            {match.params.category === 'my' ? 'My applications' : 'Apps starter kit'}
          </Typography>
          {!ready && <CircularProgress/>}
          {ready && (
            <>
              {match.params.category === 'my' && (
                <>
                  {Object.keys(installedApps).length === 0 && (
                    <Typography color="textSecondary" className={classes.basicTitle}>
                      There are no applications in your list. Please install and enjoy it!
                    </Typography>
                  )}
                  {Object.keys(installedApps).length !== 0 && (
                    <Grid container>
                      {[...apps.values()].map(app => {
                        return installedApps[app.id] && (
                          <Grid item key={app.id}>
                            <MyApplicationBlock app={app}/>
                          </Grid>
                        )
                      })}
                    </Grid>
                  )}
                </>
              )}
              {match.params.category !== 'my' && (
                <Grid container>
                  {[...apps.values()].map(app => (
                    <Grid item key={app.id}>
                      <ApplicationBlock
                        appName={app.name}
                        logoURL={app.logoURL}
                        installed={installedApps[app.id]}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default connectService(
  () => AppsService.create(),
  ({apps, ready}, appsService) => ({
    apps, ready, appsService,
    onStart() {
      return appsService.init();
    },
    onEnd() {
      appsService.stop();
    }
  })
)(
  checkAuth(
    withRouter(
      withSnackbar(
        withStyles(applicationsScreenStyles)(ApplicationsScreen)
      )
    )
  )
);
