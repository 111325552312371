const appStoreBarStyles = theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: theme.zIndex.drawer + 1
  },
  appName: {
    width: 'fit-content',
    marginRight: theme.spacing(4.5)
  },
  logout: {
    marginLeft: 'auto'
  }
});

export default appStoreBarStyles;
