const profileStyles = theme => ({
  container: {
    display: 'flex',
    padding: `0px ${theme.spacing(5)}px`,
    margin: `${theme.spacing(3)}px auto`
  },
  paper: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2.5),
    maxWidth: theme.spacing(90),
    maxHeight: 'max-content',
    overflow: 'hidden',
    position: 'relative'
  },
  textField: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  textFieldLabel: {
    marginLeft: theme.spacing(1)
  },
  form: {
    flexDirection: 'column'
  },
  button: {
    margin: 'auto',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  gridButton: {
    textAlign: 'end'
  },
  checkbox: {
    marginLeft: theme.spacing(1)
  },
  submitPassword: {
    marginRight: theme.spacing(2)
  },
  basicTitle: {
    marginRight: 'auto',
    marginBottom: theme.spacing(3)
  }
});

export default profileStyles;
