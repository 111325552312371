import React, {useEffect} from 'react';
import {withSnackbar} from "notistack";
import {connectService} from "global-apps-common";
import AuthContext from "../../modules/auth/AuthContext";
import {withStyles} from "@material-ui/core";
import googleSignInStyles from "./googleSignInStyles";

function GoogleSignIn({enqueueSnackbar, authWithGoogle, classes}) {
  useEffect(() => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        onLoad: window.gapi.signin2.render('googleSignIn', {
          width: 'auto',
          onsuccess: () => {},
          onfailure: error => {
            enqueueSnackbar(error.message, {
              variant: 'error'
            });
          }
        })
      });
    });
  }, []);

  function getCodeToken() {
    const googleAPI = window.gapi.auth2.getAuthInstance();
    googleAPI.grantOfflineAccess()
      .then(authResult => {
        if (authResult.code) {
          return authWithGoogle(authResult.code);
        }
      })
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      });
  }

  return (
    <div
      id="googleSignIn"
      className={classes.googleButton}
      onClick={getCodeToken.bind(this)}
    />
  )
}

export default connectService(AuthContext, (state, authService) => ({
  authService,
  async authWithGoogle(tokenCode) {
    await authService.authWithGoogle(tokenCode);
  }
}))(
  withSnackbar(
    withStyles(googleSignInStyles)(GoogleSignIn)
  )
);
