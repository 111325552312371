import React, {useMemo, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ProfileService from '../../modules/profile/ProfileService';
import profileStyles from './profileStyles';
import {withSnackbar} from "notistack";
import CircularProgress from '../CircularProgress';
import MainLayout from '../MainLayout';
import checkAuth from '../../common/checkAuth';
import IconButton from "@material-ui/core/IconButton";
import {VisibilityOff, Visibility} from "@material-ui/icons";
import {InputAdornment} from '@material-ui/core'
import PasswordDialog from "../PasswordDialog";
import LinearProgress from '../LinearProgress';
import {useService, initService} from "global-apps-common";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AppsService from "../../modules/apps/AppsService";
import Suggestions from '../Suggestions'

function ProfileScreenView({
                             classes,
                             ready,
                             onFieldUpdate,
                             showPassword,
                             showPasswordDialog,
                             onPasswordDialogClose,
                             onPasswordDialogSubmit,
                             onShowPasswordToggle,
                             onSubmit,
                             loading,
                             apps,
                             profileChanges,
                           }) {
  return (
    <MainLayout>
      <Container className={classes.container}>
        <Grid>
          <Typography variant="h5" className={classes.basicTitle}>
            Account
          </Typography>
          <Grid container justify="center">
            {!ready && <CircularProgress/>}
            {showPasswordDialog && (
              <PasswordDialog
                open={showPasswordDialog}
                onClose={onPasswordDialogClose}
                onChange={onFieldUpdate}
                onSubmit={onPasswordDialogSubmit}
              />
            )}
            {ready && (
              <Paper className={classes.paper}>
                <form onSubmit={onSubmit} className={classes.form}>
                  <Grid container>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        disabled={loading}
                        name="username"
                        label="Username"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel
                          }
                        }}
                        value={profileChanges.username}
                        onChange={(event) => {
                          onFieldUpdate(event, "username")
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        disabled={loading}
                        name="password"
                        label="New Password"
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel
                          }
                        }}
                        value={profileChanges.newPassword || ''}
                        onChange={(event) => {
                          onFieldUpdate(event, "newPassword")
                        }}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={onShowPasswordToggle}
                              >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid container item>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={loading}
                          name="firstName"
                          label="First Name"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabel
                            }
                          }}
                          value={profileChanges.firstName}
                          onChange={(event) => {
                            onFieldUpdate(event, "firstName")
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={loading}
                          name="lastName"
                          label="Last Name"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            classes: {
                              root: classes.textFieldLabel
                            }
                          }}
                          value={profileChanges.lastName}
                          onChange={(event) => {
                            onFieldUpdate(event, "lastName")
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        disabled={loading}
                        name="email"
                        label="Email"
                        fullWidth
                        required
                        className={classes.textField}
                        InputLabelProps={{
                          classes: {
                            root: classes.textFieldLabel
                          }
                        }}
                        value={profileChanges.email}
                        onChange={(event) => {
                          onFieldUpdate(event, "email")
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid container item alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkbox}
                              name="exported"
                              disabled={loading}
                              checked={profileChanges.isPublic}
                              value={profileChanges.isPublic}
                              color="primary"
                              onChange={(event) => {
                                onFieldUpdate(event, "isPublic")
                              }}
                            />
                          }
                          label="Public profile"
                        />
                      </Grid>
                      <Grid
                        item xs={12} sm={6}
                        className={classes.gridButton}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          disabled={loading}
                        >
                          Update profile
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
                {loading && <LinearProgress/>}
              </Paper>
            )}
          </Grid>
        </Grid>
        <Suggestions
          apps={apps}
          title='Latest Activity'
          content='Great app! It is easy to use and very comfortable!'
        />
      </Container>
    </MainLayout>
  );
}

function ProfileScreen({classes, enqueueSnackbar}) {
  const [profileStateChanges, setProfileStateChanges] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const {appsService, profileService} = useMemo(() => {
    const appsService =  AppsService.create();
    const profileService = ProfileService.create();
    return {appsService, profileService}
  }, []);

  initService(profileService, err => enqueueSnackbar(err.message, {
    variant: 'error'
  }));
  initService(appsService, err => enqueueSnackbar(err.message, {
    variant: 'error'
  }));

  const {profile, ready, loading} = useService(profileService);
  const {apps} = useService(appsService);

  function updateProfile() {
    for (let field in profileStateChanges) {
      if (profileStateChanges[field] === profile[field]) {
        return;
      }
    }
    if (!(Object.keys(profileStateChanges).length === 0)) {
      profileService.updateProfile(profileStateChanges)
        .then(() => {
          enqueueSnackbar('Profile was updated', {
            variant: 'success'
          });
        })
        .catch(err => {
          enqueueSnackbar(err.message, {
            variant: 'error'
          });
        })
        .finally(() => {
          setProfileStateChanges({});
        })
    }
  }

  const props = {
    classes,
    ready,
    loading,
    apps,
    showPassword,
    showPasswordDialog,
    profileChanges: {
      ...profile,
      ...profileStateChanges
    },

    onFieldUpdate(event, field) {
      let value = event.target.value;
      if (field === "username") {
        value = value.toLowerCase();
      }
      if (field === "isPublic") {
        value = event.target.checked;
      }
      setProfileStateChanges({...profileStateChanges, [field]: value});
    },

    onShowPasswordToggle() {
      setShowPassword(!showPassword);
    },

    onPasswordDialogClose() {
      setShowPasswordDialog(false);
    },

    onSubmit(event) {
      event.preventDefault();
      if (profileStateChanges.newPassword === '') {
        setProfileStateChanges({...profileStateChanges, newPassword: null});
      } else {
        if (profileStateChanges.newPassword !== undefined && profile.isPasswordExist) {
          setShowPasswordDialog(true);
          return;
        }
      }
      updateProfile();
    },

    onPasswordDialogSubmit() {
      setShowPasswordDialog(false);
      updateProfile();
    }
  };

  return <ProfileScreenView {...props}/>
}

export default withSnackbar(
  checkAuth(
    withStyles(profileStyles)(ProfileScreen)
  )
);
