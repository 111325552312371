const progressBarStyles = () => ({
  progressBar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0
  }
});

export default progressBarStyles;
