const installAppStyles = theme => ({
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  snackbar: {
    width: '100%',
    backgroundColor: theme.palette.dangerous.main
  }
});

export default installAppStyles;
