import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import licenceAgreementStyles from "./licenceAgreementStyles";
import {withStyles} from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function LicenceAgreement({open, onClose, terms, onSubmit, classes}) {
  const [licenceAgree, setLicenceAgree] = useState(false);

  function onCheckboxUpdate(event) {
    setLicenceAgree(event.target.checked);
  }

  function onSetLicence() {
    setLicenceAgree(false);
  }

  return (
    <Dialog open={open} onClose={() => {onSetLicence(); onClose();}}>
      <DialogTitle>Licence Agreement</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.content} dangerouslySetInnerHTML={{__html: terms}}/>
      </DialogContent>
      <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox
            checked={licenceAgree}
            color="primary"
            onChange={onCheckboxUpdate}
          />
        }
        label="I accept the Terms of Licence Agreement"
      />
      <DialogActions>
        <Button onClick={() => {onSetLicence(); onClose()}} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {onSetLicence(); onSubmit();}}
          disabled={!licenceAgree}
          variant="contained"
          color="primary"
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(licenceAgreementStyles)(LicenceAgreement);
