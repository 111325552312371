const drawerStyles = theme => ({
  paper: {
    height: '100%'
  },
  list: {
    padding: theme.spacing(1)
  },
  link: {
    textDecoration: 'none'
  },
  menuItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  iconButton: {
    minWidth: theme.spacing(4.5)
  }
});

export default drawerStyles;
