import React, {useState, useEffect, useMemo} from 'react';
import qs from 'query-string';
import {Typography} from "@material-ui/core";
import {withSnackbar} from "notistack";
import withStyles from "@material-ui/core/styles/withStyles";
import oauthScreenStyles from "./oauthScreenStyles";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {AccountCircle} from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import {Redirect} from "react-router-dom";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import LinearProgress from '@material-ui/core/LinearProgress';
import SmallBlockLayout from "../SmallBlockLayout";
import ProfileService from "../../modules/profile/ProfileService";
import {useService, isValidURL, initService, connectService} from "global-apps-common";
import AuthContext from "../../modules/auth/AuthContext";

function OAuthScreenView({
                           onSubmit,
                           authorized,
                           classes,
                           app,
                           loading,
                           redirectURI,
                           profile,
                           onLogout
                         }) {
  if (!authorized) {
    return (
      <Redirect to={'/signIn?redirectURI=' + redirectURI}/>
    );
  }

  if (!isValidURL(redirectURI)) {
    return (
      <SmallBlockLayout>
        <SnackbarContent
          className={classes.snackbar}
          message={'Error! Incorrect redirect URL.'}
        />
      </SmallBlockLayout>
    );
  }

  return (
    <SmallBlockLayout>
      <Paper className={classes.paper}>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          className={classes.subTitle}
        >
          Sign in with Global App Store
        </Typography>
        <Divider/>
        <div className={classes.titleContainer}>
          <Typography
            color="inherit"
            variant="h5"
            className={classes.title}
          >
            Choose an account
          </Typography>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            to continue {app === null ? 'for unknown application' : app}
          </Typography>
        </div>
        <List className={classes.root}>
          <ListItem
            className={classes.listItem}
            disabled={loading}
            button
            onClick={onSubmit.bind(this, redirectURI)}
          >
            <div className={classes.avatarContainer}>
              <Avatar className={classes.avatar}/>
            </div>
            <ListItemText
              className={classes.listItemText}
              primary={profile.firstName + ' ' + profile.lastName}
              secondary={profile.email}
              classes={{primary: classes.primaryText}}
            />
          </ListItem>
          <ListItem
            className={classes.listItemAnotherAcc}
            onClick={onLogout}
            disabled={loading}
            button
          >
            <div className={classes.avatarContainer}>
              <ListItemIcon className={classes.listItemIcon}>
                <AccountCircle/>
              </ListItemIcon>
            </div>
            <ListItemText
              className={classes.listItemText}
              primary="Use another account"
              classes={{primary: classes.primaryText}}
            />
          </ListItem>
        </List>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          className={classes.description}
        >
          This application will get your public key and profile data.
        </Typography>
        {loading && <LinearProgress className={classes.progressBar}/>}
      </Paper>
    </SmallBlockLayout>
  );
}

function OAuthScreen({location, history, classes, enqueueSnackbar, authorized, app, authService}) {
  const [loading, setLoading] = useState(false);
  const {redirectURI} = qs.parse(location.search);
  const profileService = useMemo(() => (
    ProfileService.create()
  ), []);

  initService(profileService, err => enqueueSnackbar(err.message, {
    variant: 'error'
  }), authorized);

  useEffect(() => {
    authService.fetchRedirectURL(redirectURI)
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      });
  }, [redirectURI]);

  useEffect(() => {
    if (!authorized) {
      history.push('/signIn?redirectURI=' + redirectURI)
    }
  }, [authorized]);

  const {profile} = useService(profileService);

  const props = {
    classes,
    profile,
    redirectURI,
    authorized,
    loading,
    app,

    onSubmit(redirectURI) {
      authService.generateAuthToken()
        .then(token => {
          setLoading(true);
          const {url, query} = qs.parseUrl(redirectURI);
          query.token = token;
          window.location.href = `${url}?${qs.stringify(query)}`;
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        });
    },

    onLogout() {
      setLoading(true);
      authService.logout()
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: 'error'
          });
        });
    }
  };

  return <OAuthScreenView {...props}/>
}

export default connectService(AuthContext, ({authorized, app}, authService) =>
  ({authorized, app, authService})
)(
  withSnackbar(
    withStyles(oauthScreenStyles)(OAuthScreen)
  )
);
