const applicationsScreenStyles = theme => ({
  basicTitle: {
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  tabRoot: {
    minHeight: 'auto',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  tabWrapper: {
    flexDirection: 'row-reverse',
    alignItems: 'flex-end'
  },
  expandMoreIcon: {
    margin: `0px ${theme.spacing(0.5)}px !important`
  },
  tabsPaper: {
    boxShadow: 'none',
    borderLeft: '1px solid #ebebeb',
    borderBottom: '1px solid #ebebeb'
  },
  appsContainer: {
    margin: `${theme.spacing(3)}px ${theme.spacing(4)}px`
  }
});

export default applicationsScreenStyles;
