import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import MULinearProgress from '@material-ui/core/LinearProgress';
import progressBarStyles from './progressBarStyles';

function LinearProgress({classes, ...otherProps}) {
  return (
    <MULinearProgress className={classes.progressBar} {...otherProps}/>
  );
}

export default withStyles(progressBarStyles)(LinearProgress);
