import React from 'react';
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import {withStyles} from "@material-ui/core";
import commentsStyles from "./commentsStyles";

function Comments({classes}) {
  return (
    <Grid container>
      <Typography variant="h6">Reviews</Typography>
      {_.times(5, id => (
        <Grid key={id} container className={classes.comment}>
          <Grid item className={classes.avatarBlock}>
            <Avatar className={classes.avatar}>MJ</Avatar>
          </Grid>
          <Grid item className={classes.commentBlock}>
            <Grid container alignItems="center">
              <Typography
                variant="subtitle1"
                className={classes.commentTitle}
              >
                Mark Jameson
              </Typography>
              <Rating className={classes.commentRating} value={4} size="small" readOnly/>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.commentDate}
              >
                19/10/2019
              </Typography>
            </Grid>
            <Typography variant="body1" color="textPrimary">
              Great app! Keep in mind that when someone recommends a business, they can select an audience to share it
              with.
              For example, if they post a Recommendation and select Friends as the audience, only their friends can
              see their Recommendation.
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default withStyles(commentsStyles)(Comments);
