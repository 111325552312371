import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import smallBlockLayoutStyles from './smallBlockLayoutStyles';
import {withStyles} from '@material-ui/core';

function SmallBlockLayout({classes, children, title}) {
  return (
    <Container
      maxWidth="sm"
      className={classes.container}
    >
      {title && (
        <Typography
          variant="h2"
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      {children}
    </Container>
  );
}

export default withStyles(smallBlockLayoutStyles)(SmallBlockLayout);
