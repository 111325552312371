const suggestionsStyles = theme => ({
  suggestions: {
    margin: theme.spacing(5),
    width: theme.spacing(45),
    maxHeight: theme.spacing(137),
    overflow: 'hidden'
  },
  card: {
    display: 'flex',
    margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    maxHeight: theme.spacing(20)
  },
  suggestionsTitle: {
    padding: `0px ${theme.spacing(1)}px`
  },
  cardActionImage: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  },
  cardImage: {
    objectFit: 'contain',
    width: theme.spacing(20)
  },
  appName: {
    maxWidth: theme.spacing(17.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: `''`,
      position: 'absolute',
      right: 0,
      top: 0,
      width: theme.spacing(5),
      height: '100%',
      background: 'linear-gradient(to right, transparent 0px, white)'
    }
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  cardActions: {
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  installedIcon: {
    color: theme.palette.success.main,
    fontSize: '1rem'
  },
  description: {
    height: theme.spacing(5),
    overflow: 'hidden',
    marginTop: theme.spacing(1)
  },
  rating: {
    fontSize: '0.9rem'
  },
  cardContent: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
  }
});

export default suggestionsStyles;
