import React from 'react';
import circularProgressStyles from './circularProgressStyles';
import withStyles from "@material-ui/core/styles/withStyles";
import MUICircularProgress from "@material-ui/core/CircularProgress";

function CircularProgress({classes, className, size}) {
  return (
    <MUICircularProgress size={size || 40} className={`${className || ''} ${classes.circularProgress}`}/>
  );
}

export default withStyles(circularProgressStyles)(CircularProgress);
