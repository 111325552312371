const subscriptionsScreenStyles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  hostingLogo: {
    marginRight: theme.spacing(2),
    verticalAlign: 'middle'
  },
  tableCellHeader: {
    fontWeight: 600
  },
  basicTitle: {
    marginRight: 'auto',
    marginBottom: theme.spacing(3)
  },
  container: {
    margin: `${theme.spacing(3)}px 0px}`
  }
});

export default subscriptionsScreenStyles;
