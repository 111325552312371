const hostingBlockStyles = theme => {
  return ({
    hosting: {
      margin: `${theme.spacing(1)}px 0px`
    },
    button: {
      marginLeft: theme.spacing(1)
    },
    link: {
      textDecoration: 'none'
    },
    hostingName: {
      margin: `0px ${theme.spacing(2)}px`
    },
    hostingTitle: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center'
    },
    hostingLogo: {
      marginRight: theme.spacing(2),
      verticalAlign: 'middle'
    },
    tableCell: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    paper: {
      width: '100%'
    },
    loader: {
      marginRight: theme.spacing(1.5),
      display: 'flex'
    }
  });
};

export default hostingBlockStyles;
