const signUpStyles = theme => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  signLink: {
  margin: theme.spacing(2)
}
});

export default signUpStyles;
