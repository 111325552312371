import React, {useEffect, useMemo} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '../CircularProgress';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import {withSnackbar} from 'notistack';
import SmallBlockLayout from '../SmallBlockLayout';
import AppsService from '../../modules/apps/AppsService';
import installAppStyles from './installAppStyles';
import checkAuth from '../../common/checkAuth';
import {initService, useService} from "global-apps-common";

function InstallAppScreenView({classes, app, error}) {
  return (
    <SmallBlockLayout title={`Installing ${app ? app.name : ''}`}>
      {error && (
        <SnackbarContent
          className={classes.snackbar}
          message={
            <span className={classes.message}>
              <ErrorIcon className={classes.icon}/>
              Error during application installation.
            </span>
          }
        />
      )}
      {!error && <CircularProgress/>}
    </SmallBlockLayout>
  );
}

function InstallAppScreen({classes, enqueueSnackbar, match}) {
  const appsService = useMemo(() => (
    AppsService.create()
  ), []);

  initService(appsService, error => enqueueSnackbar(error.message, {
    variant: 'error'
  }));

  const {apps, ready} = useService(appsService);
  const app = apps.get(Number(match.params.appId));
  const registry = app && app.registry.get(Number(match.params.hostingId));
  const error = ready && (!app || !registry || registry.status === 'NOT_OK');

  useEffect(() => {
    if (registry && registry.status === 'OK') {
      window.location = registry.url;
    }
  }, [registry]);

  const props = {
    classes,
    app,
    error
  };

  return <InstallAppScreenView {...props}/>
}

export default checkAuth(
  withSnackbar(
    withStyles(installAppStyles)(InstallAppScreen)
  )
);
