const applicationBlockStyles = theme => ({
  card: {
    margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    position: 'relative',
    overflow: 'hidden',
    maxWidth: theme.spacing(20)
  },
  cardImage: {
    objectFit: 'scale-down'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  appName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: `''`,
      position: 'absolute',
      right: 0,
      top: 0,
      width: theme.spacing(5),
      height: '100%',
      background: 'linear-gradient(to right, transparent 0px, white)'
    }
  },
  appLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  cardActions: {
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  installedIcon: {
    color: theme.palette.success.main
  },
  cardContent: {
    paddingTop: theme.spacing(1)
  },
  cardActionImage: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  }
});

export default applicationBlockStyles;
