import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import passwordDialogStyles from "./passwordDialogStyles";
import {withStyles} from '@material-ui/core';

function PasswordDialog({open, onClose, onChange, onSubmit, classes}) {
  const [showPassword, setShowPassword] = useState(false);

  function onShowPasswordToggle() {
    setShowPassword(!showPassword);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your current password to set up a new password
          </DialogContentText>
          <TextField
            name="password"
            label="Current Password"
            fullWidth
            required
            type={showPassword ? 'text' : 'password'}
            onChange={(event) => {
              onChange(event, "oldPassword")
            }}
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={onShowPasswordToggle}
                  >
                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitPassword}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default withStyles(passwordDialogStyles)(PasswordDialog);
