import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import myApplicationBlockStyles from './myApplicationBlockStyles';
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";

function MyApplicationBlock({app, classes}) {
  const readyHosting = [...app.registry.values()]
    .find(({status}) => status === 'OK');

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={readyHosting
        ? `//${app.appURL}`
        : `/install/${app.id}/${app.registry.values().next().value.hostingId}`
      }
      className={classes.appLink}
    >
      <Card className={classes.card}>
        <CardActionArea className={classes.cardActionImage}>
          <CardMedia
            component="img"
            height="140"
            className={classes.cardImage}
            image={app.logoURL}
            title={app.name}
          />
        </CardActionArea>
        <CardContent classes={{root: classes.cardContentRoot}}>
          <Typography
            variant="h6"
            component="h2"
            color="textSecondary"
            title={app.name}
            className={classes.appName}
          >
            {app.name}
          </Typography>
        </CardContent>
      </Card>
    </a>
  );
}

export default withStyles(myApplicationBlockStyles)(MyApplicationBlock);
