import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3e79ff',
      contrastText: '#fff'
    },
    secondary: {
      main: '#f44336',
      contrastText: '#000',
      lightGrey: '#ccc',
      textSecondary: 'rgba(0, 0, 0, 0.54)'
    },
    success: {
      main: '#008000'
    },
    dangerous: {
      main: '#ff0000'
    }
  }
});

export default theme;
