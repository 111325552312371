import React from 'react';
import {Typography, withStyles, Button, Card} from "@material-ui/core";
import suggestionsStyles from "./suggestionsStyles";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CardActionArea from "@material-ui/core/CardActionArea";
import {Link} from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Rating from "@material-ui/lab/Rating";

function Suggestions({classes, apps, currentApp, title, content}) {
  let activityCounter = 0;
  const isInstalled = app => {
    const readyHosting = [...app.registry.values()]
      .find(({status}) => status === 'OK');
    const pendingHosting = [...app.registry.values()]
      .find(({status}) => status === 'PENDING');
    return pendingHosting || readyHosting;
  };

  return (
    <Grid className={classes.suggestions}>
      <Grid
        className={classes.suggestionsTitle}
        container
        justify='space-between'
        alignItems='center'
      >
        <a href='#' className={classes.link}>
          <Typography variant='subtitle1'>
            {title}
          </Typography>
        </a>
        <Button size="small" color="primary">See more</Button>
      </Grid>
      {[...apps.values()].map(app => {
        if (app === currentApp || activityCounter > 3) {
          return;
        }
        if (content) {
          activityCounter++;
        }
        return (
          <Grid>
            <Card className={classes.card}>
              <Link to={'/' + app.name}>
                <CardActionArea className={classes.cardActionImage}>
                  <CardMedia
                    component="img"
                    height="104"
                    className={classes.cardImage}
                    image={app.logoURL}
                    title={app.name}
                  />
                </CardActionArea>
              </Link>
              <Grid container direction="column">
                <CardContent className={classes.cardContent}>
                  <Link to={'/' + app.name} className={classes.link}>
                    <Typography
                      variant="h6"
                      component="h2"
                      title={app.name}
                      className={classes.appName}
                    >
                      {app.name}
                    </Typography>
                  </Link>
                  <Link href='#' className={classes.link}>
                    <Typography variant="subtitle2" color="textSecondary">
                      SoftIndex LLC
                    </Typography>
                  </Link>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textSecondary"
                  >
                    {content ? content : app.description}
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Rating className={classes.rating} value={4}/>
                  {isInstalled(app) && !content && (
                    <CheckCircleIcon className={classes.installedIcon} fontSize="small"/>
                  )}
                </CardActions>
              </Grid>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default withStyles(suggestionsStyles)(Suggestions);
