import {Service, GlobalAppStoreAPI} from 'global-apps-common';

class ProfileService extends Service {
  constructor(globalAppStoreAPI) {
    super({
      profile: {},
      ready: false,
      loading: false
    });

    this._globalAppStoreAPI = globalAppStoreAPI;
  }

  async init() {
    await this.getProfileInfo();
    this.setState({ready: true});
  }

  static create() {
    return new ProfileService(GlobalAppStoreAPI.create());
  }

  async getProfileInfo() {
    const profile = await this._globalAppStoreAPI.getMyAppStoreProfile();
    this.setState({profile});
  }

  async updateProfile(profileChanges) {
    this.setState({loading: true});
    try {
      await this._globalAppStoreAPI.updateProfile(profileChanges);
    } finally {
      this.setState({loading: false});
    }
    await this.getProfileInfo();
  }
}

export default ProfileService;
