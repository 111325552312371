const oauthScreenStyles = theme => ({
  paper: {
    width: theme.spacing(56),
    borderRadius: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.secondary.lightGrey,
    boxShadow: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    overflow: 'hidden'
  },
  subTitle: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  title: {
    marginBottom: theme.spacing(1),
    textAlign: 'center'
  },
  titleContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  description: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(5),
    paddingBottom: theme.spacing(1)
  },
  listItem: {
    paddingLeft: 0,
    display: 'flex',
    verticalAlign: 'middle'
  },
  listItemText: {
    flexGrow: 1
  },
  primaryText: {
    fontSize: '0.95rem'
  },
  listItemAnotherAcc: {
    display: 'flex',
    verticalAlign: 'middle',
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
  },
  avatarContainer: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    justifyContent: 'center',
    textAlign: 'center',
    width: 62
  },
  avatar: {
    width: 30,
    height: 30
  },
  listItemIcon: {
    textAlign: 'center',
    minWidth: 0,
    marginTop: theme.spacing(0.25)
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.contrastText
  },
  snackbar: {
    margin: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 600,
    backgroundColor: theme.palette.dangerous.main
  },
  progressBar: {
    bottom: 0,
    width: '100%',
    left: 0
  }
});

export default oauthScreenStyles;
