import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import hostingBlockStyles from './hostingBlockStyles';
import LicenceAgreement from "../LicenceAgreement";
import {Visibility} from "@material-ui/icons";
import {CircularProgress} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

function HostingBlock({
                        hosting,
                        registry,
                        appId,
                        classes,
                        requesting,
                        deleting,
                        onInstall,
                        onUninstall
                      }) {
  const showOpen = deleting || (registry && ['PENDING', 'OK'].includes(registry.status));
  const showLoading = requesting || deleting || (registry && registry.status === 'PENDING');
  const [showLicence, setShowLicence] = useState(false);
  const visibilityIcon = (
    <IconButton
      className={classes.button}
      disabled={deleting}
    >
      <Visibility/>
    </IconButton>
  );

  function onClickInstall() {
    setShowLicence(true);
  }

  function onCloseLicenceAgreement() {
    setShowLicence(false);
  }

  return (
    <>
      <ListItem button>
        <ListItemIcon>
          <img
            width={80}
            height={40}
            src={hosting.logoUrl}
            alt={hosting.name}
            className={classes.hostingLogo}
          />
        </ListItemIcon>
        <ListItemText primary={hosting.name}/>
        {showOpen && (
          <ListItemSecondaryAction>
            <IconButton
              onClick={onUninstall}
              disabled={deleting}
            >
              <DeleteIcon/>
            </IconButton>
            {registry && registry.status === 'OK' && (
              <a href={registry.url} target="_blank" className={classes.link}>{visibilityIcon}</a>
            )}
            {registry && registry.status !== 'OK' && (
              <Link to={`/install/${appId}/${hosting.id}`} target="_blank" className={classes.link}>{visibilityIcon}</Link>
            )}
          </ListItemSecondaryAction>
        )}
        {!showOpen && !showLoading && (
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => {
                onClickInstall()
              }}
              color="primary"
              disabled={requesting}
            >
              <GetAppIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        )}
        {showLoading && !showOpen && (
          <ListItemSecondaryAction className={classes.loader}>
            <CircularProgress size={24}/>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <LicenceAgreement
        open={showLicence}
        onClose={() => {
          onCloseLicenceAgreement()
        }}
        onSubmit={() => {
          onCloseLicenceAgreement();
          onInstall()
        }}
        terms={hosting.terms}
      />
    </>
  );
}

export default withStyles(hostingBlockStyles)(HostingBlock);
