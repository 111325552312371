const hostingStyles = theme => {
  return ({
    loader: {
      marginRight: theme.spacing(1.5)
    },
    hosting: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: 'all .2s ease-in-out'
    },
    expandedHosting: {
      maxHeight: theme.spacing(150),
      transition: 'all .2s ease-in-out',
      marginBottom: theme.spacing(4)
    },
    hostingLogo: {
      marginRight: theme.spacing(2)
    },
    circularProgress: {
      marginBottom: theme.spacing(5)
    }
  });
};

export default hostingStyles;
