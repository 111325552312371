import React, {useState, useEffect} from 'react';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {withStyles, withWidth} from "@material-ui/core";
import signUpStyles from "./signUpStyles";
import {withRouter} from "react-router-dom";
import {withSnackbar} from "notistack";
import qs from "query-string";
import SmallBlockLayout from '../SmallBlockLayout';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import GoogleSignIn from "../GoogleSignIn";
import {connectService} from "global-apps-common";
import AuthContext from "../../modules/auth/AuthContext";
import afterAuthorizeRedirect from "../afterAuthorizeRedirect/afterAuthorizeRedirect";

function SignUpScreenView({classes, onSubmit, redirectURI, accountData, redirectedFromGoogle, onChange}) {
  return (
    <SmallBlockLayout title="Global App Store">
      <Typography
        align="center"
        component="h1"
        variant="h5"
      >
        Please fill out the requested information to sign up
      </Typography>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          name="email"
          label="Email"
          type="email"
          value={accountData.email}
          onChange={event => onChange(event, 'email')}
          fullWidth
          autoFocus
          disabled={redirectedFromGoogle}
        />
        {!redirectedFromGoogle && (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={accountData.password}
            onChange={event => onChange(event, 'password')}
            autoComplete="current-password"
          />
        )}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="First Name"
          name="firstName"
          autoComplete="firstName"
          value={accountData.firstName}
          onChange={event => onChange(event, 'firstName')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Last Name"
          name="lastName"
          autoComplete="lastName"
          value={accountData.lastName}
          onChange={event => onChange(event, 'lastName')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Username"
          name="username"
          autoComplete="username"
          value={accountData.username}
          onChange={event => onChange(event, 'username')}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign Up
        </Button>
        <GoogleSignIn/>
      </form>
      <Link
        className={classes.signLink}
        href={'/signIn' + (redirectURI ? 'redirectURI=' + redirectURI : '')}
        variant="body2"
      >
        Already have an account? Sign In
      </Link>
    </SmallBlockLayout>
  );
}

function SignUpScreen({classes, location, preregisterData, onSubmit}) {
  const {redirectURI} = qs.parse(location.search);
  const [accountData, setAccountData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    username: ''
  });

  useEffect(() => {
    if (preregisterData) {
      setAccountData({
        ...preregisterData,
        username: ''
      });
    }
  }, [preregisterData]);

  const props = {
    classes,
    redirectURI,
    accountData,
    redirectedFromGoogle: Boolean(preregisterData),

    onSubmit(event) {
      event.preventDefault();
      const newUser = {
        ...accountData,
        password: preregisterData ? null : accountData.password
      };
      onSubmit(newUser, preregisterData);
    },

    onChange(event, field) {
      setAccountData({...accountData, [field]: event.target.value})
    }
  };

  return <SignUpScreenView {...props}/>
}

export default withWidth()(
  withRouter(
    withSnackbar(
      afterAuthorizeRedirect(
        connectService(AuthContext, ({preregisterData}, authService, {enqueueSnackbar}) => ({
          preregisterData,
          onSubmit(newUser, preregisterData) {
            (async () => {
              if (!preregisterData) {
                await authService.signup(newUser);
              } else {
                await authService.signupByGoogle({
                  ...newUser,
                  accessToken: preregisterData.accessToken
                });
              }
            })()
              .catch(error => enqueueSnackbar(error.message, {
                variant: 'error'
              }));
          }
        }))(
          withStyles(signUpStyles)(SignUpScreen)
        )
      )
    )
  )
);

