import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import PopupState, {bindPopover, bindTrigger} from 'material-ui-popup-state';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import withStyles from '@material-ui/core/styles/withStyles';
import appStoreBarStyles from './appStoreBarStyles';
import Search from '../Search';

function AppStoreBar({classes, onLogout}) {
  return (
    <AppBar
      position="sticky"
      className={classes.appBar}
    >
      <Toolbar>
        <Typography className={classes.appName} variant="h6" noWrap>
          Global App Store
        </Typography>
        <Search/>
        <PopupState variant="popover">
          {popupState => (
            <>
              <IconButton
                className={classes.logout}
                edge="start"
                color="inherit" {...bindTrigger(popupState)}
              >
                <PersonIcon/>
              </IconButton>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Button onClick={onLogout}>Log out</Button>
              </Popover>
            </>
          )}
        </PopupState>
      </Toolbar>
    </AppBar>
  );
}

export default withStyles(appStoreBarStyles)(AppStoreBar);
