import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import applicationBlockStyles from './applicationBlockStyles';
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Rating from '@material-ui/lab/Rating';
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import {Link} from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function ApplicationBlock({classes, appName, logoURL, installed}) {
  return (
    <Card className={classes.card}>
      <CardActionArea className={classes.cardActionImage}>
        <Link to={'/' + appName}>
          <CardMedia
            component="img"
            height="140"
            className={classes.cardImage}
            image={logoURL}
            title={appName}
          />
        </Link>
      </CardActionArea>
      <CardContent className={classes.cardContent}>
        <Link to={'/' + appName} className={classes.appLink}>
          <Typography
            variant="h6"
            component="h2"
            title={appName}
            className={classes.appName}
          >
            {appName}
          </Typography>
        </Link>
        <Link to='#' className={classes.appLink}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
          >
            SoftIndex LLC
          </Typography>
        </Link>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Rating size="small" value={4} readOnly/>
        {installed && <CheckCircleIcon className={classes.installedIcon} fontSize="small"/>}
      </CardActions>
    </Card>
  );
}

export default withStyles(applicationBlockStyles)(ApplicationBlock);
