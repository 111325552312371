import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MainLayout from "../MainLayout";
import Divider from "@material-ui/core/Divider";
import paymentsScreenStyles from "./paymentsScreenStyles";
import Container from "@material-ui/core/Container";

function PaymentsScreen({classes}) {
  return (
    <MainLayout>
      <Container fixed className={classes.container}>
        <Typography variant="h5" className={classes.basicTitle}>
          Payment Methods
        </Typography>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography
              className={classes.title}
              variant="h6"
              color="textPrimary"
            >
              Payment Cards
            </Typography>
            <Button className={classes.paymentInfoButton}>
              <img
                className={classes.cardImage}
                src="https://lh3.googleusercontent.com/n9rDb1YE8FeAKvhoKWUOdshHaBTvwueVCTItVp7jCJRcCwWZ4cTuBiyqJ3pNAg9uqM_vAig1_HlTEn2I"
                alt="Mastercard"
                aria-hidden="true"/>
              <Typography variant="subtitle1" color="textSecondary">
                Mastercard-3295
              </Typography>
            </Button>
            <Button className={classes.paymentInfoButton}>
              <img
                className={classes.cardImage}
                src="https://lh3.googleusercontent.com/gOyFMX--no7R-wnUx8L3hkoz-Xu6xPS7x-RuaYsKigK7Mxc85zaaHXjiEHjr2sxHUshfoG3nCX7Jace724Q"
                alt="Visa"
                aria-hidden="true"/>
              <Typography variant="subtitle1" color="textSecondary">
                Visa-5430
              </Typography>
            </Button>
            <Button className={classes.paymentInfoButton}>
              <img
                className={classes.cardImage}
                src="https://lh3.googleusercontent.com/gOyFMX--no7R-wnUx8L3hkoz-Xu6xPS7x-RuaYsKigK7Mxc85zaaHXjiEHjr2sxHUshfoG3nCX7Jace724Q"
                alt="Visa 2"
                aria-hidden="true"/>
              <Typography variant="subtitle1" color="textSecondary">
                Visa-9532
              </Typography>
            </Button>
          </CardContent>
          <Divider/>
          <CardActions className={classes.cardActionButtons}>
            <Button
              className={classes.actionButton}
              color="primary"
              variant="outlined"
            >
              Add payment
            </Button>
            <Button
              className={classes.actionButton}
              color="primary"
              variant="outlined"
            >
              Edit payment
            </Button>
          </CardActions>
        </Card>
      </Container>
    </MainLayout>
  );
}

export default withStyles(paymentsScreenStyles)(PaymentsScreen);
