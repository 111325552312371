import {groupBy, keyBy, isEqual} from 'lodash';
import {Map, Set} from 'immutable';
import {RejectionError, delay, Service, GlobalAppStoreAPI} from 'global-apps-common';

const SCAN_REGISTRIES_INTERVAL = 1000;

class AppsService extends Service {
  constructor(globalAppStoreAPI) {
    super({
      apps: Map(),
      ready: false,
      requestingHosting: Map(),
      deletingHosting: Map()
    });

    this._globalAppStoreAPI = globalAppStoreAPI;
    this._scanInterval = null;
  }

  static create() {
    return new AppsService(GlobalAppStoreAPI.create());
  }

  async init() {
    const getApps = this._scanApps();
    const getRegistries = this._scanRegistries();

    const [apps, registries] = await Promise.all([getApps, getRegistries]);
    this._updateApps(apps, registries);

    this._makeScanInterval();
  }

  stop() {
    if (this._scanInterval) {
      this._scanInterval.cancel();
      this._scanInterval = null;
    }
  }

  install(hostingId, appId) {
    this.setState({
      requestingHosting: this.state.requestingHosting.set(
        appId,
        Set([...(this.state.requestingHosting.get(appId) || []), hostingId])
      )
    });

    this._globalAppStoreAPI.installApplication(hostingId, appId)
      .then(newAppId => {
        this.setState({
          apps: this.state.apps.setIn([appId, 'registry', hostingId], {
            appId,
            hostingId,
            id: newAppId,
            status: 'PENDING'
          })
        });
      })
      .finally(() => {
        const requestingHostingByApp = this.state.requestingHosting.get(appId).delete(hostingId);
        this.setState({
          requestingHosting: this.state.requestingHosting.set(appId, requestingHostingByApp)
        });
      });
  }

  uninstall(hostingId, appId) {
    this.setState({
      deletingHosting: this.state.deletingHosting.set(
        appId,
        Set([...this.state.deletingHosting.get(appId) || [], hostingId])
      )
    });

    this._globalAppStoreAPI.deleteApplication(hostingId, appId)
      .then(() => {
        this.setState({
          apps: this.state.apps.deleteIn([appId, 'registry', hostingId])
        });
      })
      .finally(() => {
        const deletingHostingByApp = this.state.deletingHosting.get(appId).delete(hostingId);
        this.setState({
          deletingHosting: this.state.deletingHosting.set(appId, deletingHostingByApp)
        });
      });
  }

  _makeScanInterval() {
    this._scanInterval = delay(SCAN_REGISTRIES_INTERVAL)
      .then(() => {
        return this._scanRegistries();
      })
      .then(registries => {
        this._updateApps(this.state.apps, registries);
        this._makeScanInterval();
      })
      .catch(error => {
        if (!(error instanceof RejectionError)) {
          this._makeScanInterval();
        }
      });
  }

  async _scanApps() {
    const allApps = await this._globalAppStoreAPI.getApplications();
    const appsIds = allApps.map(app => app.id);
    const appsURLs = await this._globalAppStoreAPI.getAppsURLs(appsIds);

    return allApps.reduce((map, app, index) => {
      return map.set(app.id, {
        id: app.id,
        name: app.name,
        description: app.description,
        registry: Map(),
        logoURL: app.logoUrl,
        appURL: appsURLs[index]
      });
    }, Map());
  }

  async _scanRegistries() {
    const registries = await this._globalAppStoreAPI.getRegistries();
    return registries.filter(({status}) => status !== 'NOT_OK');
  }

  _updateApps(apps, registries) {
    const registriesByAppId = groupBy(registries, 'appId');

    for (const [, app] of apps) {
      const appRegistries = registriesByAppId[app.id];
      const registryByHostingId = keyBy(appRegistries, 'hostingId');
      apps = apps.setIn(
        [app.id, 'registry'],
        Map(Object.entries(registryByHostingId).map(([, hosting]) => [hosting.hostingId, hosting]))
      );
    }

    if (!isEqual(apps, this.state.apps)) {
      this.setState({apps, ready: true});
    }
  }
}

export default AppsService;
