import React from 'react';
import {Link} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ViewModule from '@material-ui/icons/ViewModule';
import Typography from "@material-ui/core/Typography";
import PersonIcon from '@material-ui/icons/Person';
import withStyles from '@material-ui/core/styles/withStyles';
import drawerStyles from "./drawerStyles";
import PaymentIcon from '@material-ui/icons/Payment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GetAppIcon from '@material-ui/icons/GetApp';

function Drawer({classes, path}) {
  return (
    <Paper className={classes.paper}>
      <List className={classes.list}>
        <Link to="/apps" className={classes.link}>
          <MenuItem selected={path === '/apps'}>
            <ListItemIcon className={classes.iconButton}>
              <ViewModule/>
            </ListItemIcon>
            <Typography className={classes.menuItemText} color="textSecondary">Applications</Typography>
          </MenuItem>
        </Link>

        <Link to="/apps/my" className={classes.link}>
          <MenuItem selected={path === '/apps/my'}>
            <ListItemIcon className={classes.iconButton}>
              <GetAppIcon/>
            </ListItemIcon>
            <Typography className={classes.menuItemText} color="textSecondary">My Applications</Typography>
          </MenuItem>
        </Link>

        <Link to="/profile" className={classes.link}>
          <MenuItem selected={path === '/profile'}>
            <ListItemIcon className={classes.iconButton}>
              <PersonIcon/>
            </ListItemIcon>
            <Typography className={classes.menuItemText} color="textSecondary">Account</Typography>
          </MenuItem>
        </Link>

        <Link to="/payments" className={classes.link}>
          <MenuItem selected={path === '/payments'}>
            <ListItemIcon className={classes.iconButton}>
              <PaymentIcon/>
            </ListItemIcon>
            <Typography className={classes.menuItemText} color="textSecondary">Payment methods</Typography>
          </MenuItem>
        </Link>

        <Link to="/subscriptions" className={classes.link}>
          <MenuItem selected={path === '/subscriptions'}>
            <ListItemIcon className={classes.iconButton}>
              <ShoppingCartIcon/>
            </ListItemIcon>
            <Typography className={classes.menuItemText} color="textSecondary">Subscriptions</Typography>
          </MenuItem>
        </Link>
      </List>
    </Paper>
  );
}

export default withStyles(drawerStyles)(Drawer);
