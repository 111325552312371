const smallBlockLayoutStyles = theme => ({
  container: {
    padding: theme.spacing(2),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    marginBottom: theme.spacing(8)
  }
});

export default smallBlockLayoutStyles;
