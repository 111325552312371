import React from 'react';
import AppStoreBar from '../AppStoreBar';
import Drawer from '../Drawer';
import Grid from '@material-ui/core/Grid';
import mainLayoutStyles from './mainLayoutStyles';
import withStyles from "@material-ui/core/styles/withStyles";
import {connectService} from "global-apps-common";
import AuthContext from "../../modules/auth/AuthContext";
import {withSnackbar} from "notistack";

function MainLayout({onLogout, children, classes}) {
  return (
    <Grid
      container
      direction="column"
      className={classes.root}
    >
      <Grid item>
        <AppStoreBar onLogout={onLogout}/>
      </Grid>
      <Grid className={classes.rootGrid}>
        <Grid>
          <Drawer/>
        </Grid>
        <Grid container direction="column">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default connectService(
  AuthContext, (state, authService, props) => ({
    authService,
    onLogout() {
      authService.logout()
        .catch((error) => {
          props.enqueueSnackbar(error.message, {
            variant: 'error'
          });
        });
    }
  })
)(
  withSnackbar(
    withStyles(mainLayoutStyles)(MainLayout)
  )
);
