const applicationDescriptionStyles = theme => ({
  button: {
    borderRadius: theme.spacing(0.5),
    fontSize: 'medium',
    marginRight: 'auto',
    '&:hover': {
      background: 'transparent'
    }
  },
  views: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  appLink: {
    color: theme.palette.secondary.textSecondary,
    marginLeft: 3,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  appCreator: {
    marginTop: theme.spacing(2)
  },
  personIcon: {
    fontSize: '1rem',
    marginRight: theme.spacing(1)
  },
  comments: {
    paddingTop: 0
  }
});

export default applicationDescriptionStyles;
