const appInfoScreenStyles = theme => ({
  appLayout: {
    display: 'flex',
    padding: `0px ${theme.spacing(5)}px`
  },
  card: {
    maxWidth: theme.spacing(100),
    margin: `${theme.spacing(5)}px auto`
  },
  cardContent: {
    display: 'flex'
  },
  appLogo: {
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    flexGrow: 1,
    minWidth: theme.spacing(20),
    height: theme.spacing(20)
  },
  expand: {
    transform: 'rotate(0deg)',
    margin: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  descriptionCardContent: {
    paddingBottom: 0
  },
  notExpandedDescription: {
    '&:before': {
      content: `''`,
      height: '100%',
      width: '100%',
      position: 'absolute',
      background: 'linear-gradient(transparent 0px, white)'
    },
    maxHeight: theme.spacing(10),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    content: `''`,
    position: 'relative',
    transition: 'all .1s ease-in-out'
  },
  expandedDescription: {
    maxHeight: theme.spacing(200),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    content: `''`,
    position: 'relative',
    transition: 'all .3s ease-in-out'
  },
  comments: {
    paddingTop: 0
  },
  circularProgress: {
    marginTop: theme.spacing(5)
  }
});

export default appInfoScreenStyles;
