import React, {useState} from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import PersonIcon from '@material-ui/icons/Person';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import applicationDescriptionStyles from "./applicationDescriptionStyles";
import {Link} from 'react-router-dom';

function ApplicationDescription({classes, app, installed, readyHosting, onShowHosting}) {
  const [favourite, setFavourite] = useState(false);

  const onAddFavorite = () => {
    setFavourite(!favourite);
  };

  return (
    <Grid
      container
      justify="space-between"
      direction="column"
    >
      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h4">
            {app.name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.appCreator}
          >
            Created by
            <Link to="#" className={classes.appLink}>
                SoftIndex LLC
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Category:
            <Link to="#" className={classes.appLink}>
              Business
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Rating size="small" value={4} readOnly/>
          <Typography
            className={classes.views}
            variant="body2"
            color="textSecondary"
          >
            <PersonIcon fontSize="small" className={classes.personIcon}/>
            9 066 409
          </Typography>
        </Grid>
      </Grid>
      <Grid
        justify="flex-start"
        container
        direction="row-reverse"
      >
        {installed && (
          <Button
            href={readyHosting
              ? `//${app.appURL}`
              : `/install/${app.id}/${app.registry.values().next().value.hostingId}`
            }
            target="_blank"
            variant="contained"
            color="primary"
          >
            Open
          </Button>
        )}
        {!installed && (
          <Button
            onClick={onShowHosting}
            color="primary"
            variant='contained'
          >
            Install
          </Button>
        )}
        <IconButton
          onClick={onAddFavorite}
          variant="outlined"
          color="secondary"
          size='small'
          className={classes.button}
        >
          {favourite && <FavoriteIcon/>}
          {!favourite && <FavoriteBorderIcon/>}
          Add to favorites
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default withStyles(applicationDescriptionStyles)(ApplicationDescription);
