import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MainLayout from "../MainLayout";
import {connectService} from "global-apps-common";
import HostingService from "../../modules/hosting/HostingService";
import subscriptionsScreenStyles from "./subscriptionsScreenStyles";
import CircularProgress from "../CircularProgress";
import {withSnackbar} from "notistack";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

function SubscriptionsScreen({classes, onStart, hosting, ready, enqueueSnackbar}) {
  useEffect(() => {
    onStart()
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      });
  }, []);

  return (
    <MainLayout>
      <Container fixed className={classes.container}>
        <Typography variant="h5" className={classes.basicTitle}>
          Subscriptions
        </Typography>
        {!ready && <CircularProgress/>}
        {ready && (
          <Paper className={classes.root}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCellHeader}>Hosting name</TableCell>
                  <TableCell className={classes.tableCellHeader} align="center">Price/Month</TableCell>
                  <TableCell className={classes.tableCellHeader} align="center">Tariff</TableCell>
                  <TableCell className={classes.tableCellHeader} align="center">Renewal date</TableCell>
                  <TableCell className={classes.tableCellHeader} align="center"/>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...hosting.values()].map(hostingItem => (
                  <TableRow key={hostingItem.name}>
                    <TableCell component="th" scope="row">
                      <img
                        className={classes.hostingLogo}
                        src={hostingItem.logoUrl}
                        alt={hostingItem.name}
                        height={40}
                        width={80}
                      />
                      {hostingItem.name}
                    </TableCell>
                    <TableCell align="center">$2</TableCell>
                    <TableCell align="center">1 TB</TableCell>
                    <TableCell align="center">15.01.2020</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                      >
                        Unsubscribe
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Container>
    </MainLayout>
  );
}

export default connectService(
  () => HostingService.create(),
  ({hosting, ready}, hostingService) => ({
    hosting,
    ready,
    onStart() {
      return hostingService.init();
    }
  })
)(
  withSnackbar(
    withStyles(subscriptionsScreenStyles)(SubscriptionsScreen)
  )
);
