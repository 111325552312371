import React, {useEffect, useMemo, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import appInfoScreenStyles from "./appInfoScreenStyles";
import MainLayout from "../MainLayout";
import Hosting from '../Hosting'
import Screenshots from "../Screenshots";
import {connectService} from "global-apps-common";
import {withRouter} from "react-router-dom";
import AppsService from "../../modules/apps/AppsService";
import {withSnackbar} from "notistack";
import CircularProgress from '../CircularProgress';
import Comments from "../Comments";
import ApplicationDescription from "../ApplicationDescription";
import AppsContext from "../../modules/apps/AppsContext";
import Suggestions from '../Suggestions';
import {Container} from "@material-ui/core";

function ApplicationInfoScreen({classes, ready, apps, location, onStart, onEnd, enqueueSnackbar, appsService}) {
  const [showDescription, setShowDescription] = useState(false);
  const [showHosting, setShowHosting] = useState(false);

  useEffect(() => {
    onStart()
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      });
    return onEnd;
  }, []);

  const {
    app,
    readyHosting,
    installed
  } = useMemo(() => {
    let app = {};
    let readyHosting = [];
    let pendingHosting = [];
    let installed = false;

    [...apps.values()].forEach(currentApp => {
      if (currentApp.name === location.pathname.slice(1)) {
        app = currentApp;
        readyHosting = [...currentApp.registry.values()]
          .find(({status}) => status === 'OK');
        pendingHosting = [...currentApp.registry.values()]
          .find(({status}) => status === 'PENDING');
        installed = pendingHosting || readyHosting;
      }
    });
    return {
      app,
      readyHosting,
      installed
    }
  }, [apps, location]);

  const onShowHosting = () => {
    setShowHosting(!showHosting);
  };

  const onClickExpand = () => {
    setShowDescription(!showDescription);
  };

  return (
    <AppsContext.Provider value={appsService}>
      <MainLayout>
        {!ready && <CircularProgress className={classes.circularProgress}/>}
        {ready && (
          <Container className={classes.appLayout}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <CardMedia
                  className={classes.appLogo}
                  image={app.logoURL}
                  title={app.name}
                />
                <ApplicationDescription
                  app={app}
                  installed={installed}
                  readyHosting={readyHosting}
                  onShowHosting={onShowHosting}
                />
              </CardContent>
              <CardContent>
                <Hosting
                  showHosting={showHosting || installed}
                  appId={app.id}
                  appName={app.name}
                />
                <Screenshots/>
              </CardContent>
              <CardContent className={classes.descriptionCardContent}>
                <Typography className={showDescription ? classes.expandedDescription : classes.notExpandedDescription}>
                  {app.description}
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton
                  onClick={onClickExpand}
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: showDescription,
                  })}
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>
              <CardContent className={classes.comments}>
                <Comments/>
              </CardContent>
            </Card>
            <Suggestions
              currentApp={app}
              apps={apps}
              installed={installed}
              title='More by SoftIndex LLC'
            />
          </Container>
        )}
      </MainLayout>
    </AppsContext.Provider>
  );
}

export default connectService(
  () => AppsService.create(),
  ({apps, ready}, appsService) => ({
    apps,
    ready,
    appsService,
    onStart() {
      return appsService.init();
    },
    onEnd() {
      appsService.stop();
    }
  })
)(
  withSnackbar(
    withRouter(
      withStyles(appInfoScreenStyles)(ApplicationInfoScreen)
    )
  )
);
