import React, {useEffect} from 'react';
import {withSnackbar} from 'notistack';
import {connectService} from 'global-apps-common';
import HostingService from '../../modules/hosting/HostingService';
import AppsContext from '../../modules/apps/AppsContext';
import HostingBlock from '../HostingBlock';
import CircularProgress from '../CircularProgress'
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";
import hostingStyles from "./hostingStyles";
import ListSubheader from "@material-ui/core/ListSubheader";

function Hosting({
                   classes,
                   showHosting,
                   onStart,
                   onInstall,
                   onUninstall,
                   enqueueSnackbar,
                   hosting,
                   ready,
                   appId,
                   requestingHosting,
                   registry,
                   deletingHosting
                 }) {
  useEffect(() => {
    onStart()
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      });
  }, []);

  function onInstallApp(hostingId) {
    onInstall(hostingId)
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      });
  }

  function onUninstallApp(hostingId) {
    onUninstall(hostingId)
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        });
      });
  }

  if (!ready && !showHosting) {
    return null;
  }

  return (
    <Paper className={showHosting ? classes.expandedHosting : classes.hosting}>
      <List subheader={<ListSubheader>Hosting</ListSubheader>}>
        {!ready && <CircularProgress size={24} className={classes.circularProgress}/>}
        {[...hosting.values()].map(hostingItem => (
          <HostingBlock
            key={hostingItem.id}
            registry={registry.get(hostingItem.id)}
            requesting={requestingHosting.has(hostingItem.id)}
            deleting={deletingHosting.has(hostingItem.id)}
            hosting={hostingItem}
            appId={appId}
            onInstall={onInstallApp.bind(this, hostingItem.id)}
            onUninstall={onUninstallApp.bind(this, hostingItem.id)}
          />
        ))}
      </List>
    </Paper>
  );
}

export default connectService(
  AppsContext,
  ({apps, requestingHosting, deletingHosting}, appsService, props) => ({
    registry: apps.get(props.appId).registry,
    requestingHosting: requestingHosting.get(props.appId) || new Set(),
    deletingHosting: deletingHosting.get(props.appId) || new Set(),
    async onInstall(hostingId) {
      await appsService.install(hostingId, props.appId);
    },
    async onUninstall(hostingId) {
      await appsService.uninstall(hostingId, props.appId);
    }
  })
)(
  connectService(
    () => HostingService.create(),
    ({hosting, ready}, hostingService) => ({
      hosting,
      ready,
      onStart() {
        return hostingService.init();
      }
    })
  )(
    withSnackbar(
      withStyles(hostingStyles)(Hosting)
    )
  )
);
