const licenceAgreementStyles = theme => ({
  content: {
    color: theme.palette.secondary.contrastText,
    '& > *': {
      marginTop: 0
    },
    '& h2': {
      fontSize: '1.25rem'
    }
  },
  formControl: {
    margin: `0px ${theme.spacing(1.5)}px`
  }
});

export default licenceAgreementStyles;

