import {Map} from 'immutable';
import {Service, GlobalAppStoreAPI} from 'global-apps-common';

class HostingService extends Service {
  constructor(globalAppStoreAPI) {
    super({
      hosting: Map(),
      ready: false
    });

    this._globalAppStoreAPI = globalAppStoreAPI;
  }

  static create() {
    return new HostingService(GlobalAppStoreAPI.create());
  }

  async init() {
    const allHosting = await this._globalAppStoreAPI.getHosting();
    const hosting = allHosting.reduce((map, hosting) => map.set(hosting.id, hosting), Map());

    this.setState({
      hosting,
      ready: true
    });
  }
}

export default HostingService;
