const commentsStyles = theme => ({
  comment: {
    padding: theme.spacing(2)
  },
  avatarBlock: {
    minWidth: theme.spacing(7)
  },
  commentBlock: {
    flex: '1 0'
  },
  commentTitle: {
    fontWeight: 600
  },
  commentRating: {
    margin: `0px ${theme.spacing(1)}px`
  },
  commentDate: {
    marginLeft: 'auto'
  },
  avatar: {
    marginTop: theme.spacing(0.5)
  }
});

export default commentsStyles;
