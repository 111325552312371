const paymentsScreenStyles = theme => ({
  actionButton: {
    margin: `0px ${theme.spacing(1)}px`
  },
  card: {
    minWidth: theme.spacing(34),
    width: '100%',
    margin: 'inherit'
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  cardActionButtons: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  cardImage: {
    maxHeight: theme.spacing(6),
    maxWidth: theme.spacing(6),
    marginRight: theme.spacing(3)
  },
  paymentInfoButton: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'left'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  basicTitle: {
    marginRight: 'auto',
    marginBottom: theme.spacing(3)
  },
  container: {
    margin: `${theme.spacing(3)}px 0px}`
  }
});

export default paymentsScreenStyles;
